import { ISelectOption } from "../../components/Forms/Select/Select.interface";
import { APP_CONFIGS } from "../constants";
import { IRequest } from "../interfaces/authentication.interface";
import { IContactFormResponse } from "../interfaces/contacts.interface";
import { contentRequest } from "./requests.service";

export const getSubjects = async (locale: string): Promise<IRequest<ISelectOption[]>> => {
    try {
        const query = `
            query ContactForm($filters: ComponentItemTextFiltersInput, $locale: I18NLocaleCode) {
                contactForm(locale: $locale) {
                    subjects(filters: $filters) {
                        label
                        value
                    }
                }
            }`;

        const variables = {
            locale,
            filters: {
                platforms: {
                    key: {
                        eq: APP_CONFIGS.key
                    }
                }
            }
        };

        const response = await contentRequest.post<IContactFormResponse>(`/graphql`, {
            operationName: "ContactForm",
            query,
            variables
        });

        return { success: true, data: response.data.data.contactForm?.subjects ?? [] };
    } catch (error: any) {
        return { success: false, data: [] as any };
    }
};
