import { APP_CONFIGS } from "../constants";
import { IRequest } from "../interfaces/authentication.interface";
import { IModule, IModuleResponse, IModulesResponse } from "../interfaces/modules.interface";
import { contentRequest } from "./requests.service";

export const getModules = async (locale: string): Promise<IRequest<IModule[]>> => {
    try {
        const query = `
        query Modules($locale: I18NLocaleCode, $filters: ModuleFiltersInput, $status: PublicationStatus) {
            modules(locale: $locale, filters: $filters, status: $status) {
                id: documentId
                title
                description
                contentAmount
            }
        }`;

        const variables = {
            locale,
            status: "PUBLISHED",
            filters: {
                platforms: {
                    key: {
                        eq: APP_CONFIGS.key
                    }
                }
            }
        };

        const response = await contentRequest.post<IModulesResponse>(`/graphql`, {
            operationName: "Modules",
            query,
            variables
        });

        return { success: true, data: response.data.data.modules ?? [] };
    } catch (error: any) {
        return { success: false, data: [] as any };
    }
};

export const getModule = async (documentId: string, locale: string): Promise<IRequest<IModule>> => {
    try {
        const query = `
            query ModuleQuery($documentId: ID!, $locale: I18NLocaleCode, $status: PublicationStatus) {
                module(documentId: $documentId, locale: $locale, status: $status){
                    title
                    id: documentId
                    sections {                    
                        title
                        items{
                            type: __typename
                            ... on ComponentItemVideo {
                                id
                                title
                                description
                                url: vimeoUrl
                                duration
                            }
                            ... on ComponentItemTypeForm {
                                id
                                title
                                description
                                formId
                                duration 
                            }
                            ... on ComponentItemRichText {
                                id
                                title
                                content
                                duration
                            }
                            ... on ComponentItemImage {
                                id
                                title
                                description
                                duration
                                image: file{
                                    url
                                    width
                                    height
                                    alt: alternativeText
                                }
                            }
                            ... on ComponentItemFile {
                                id
                                title
                                duration
                            }
                            ... on ComponentItemAudio {
                                id
                                title
                                description
                                duration
                                audio: file{
                                    url
                                    alt: alternativeText
                                }
                            }
                            ... on Error {
                                code
                                message
                            }
                        }
                    }
                }
            }`;

        const variables = {
            documentId,
            locale,
            status: "PUBLISHED"
        };

        const response = await contentRequest.post<IModuleResponse>(`/graphql`, {
            operationName: "ModuleQuery",
            query,
            variables
        });

        if (!response.data.data.module) throw Error;

        return { success: true, data: response.data.data.module };
    } catch (error: any) {
        return { success: false, data: null as any };
    }
};
