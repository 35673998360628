import { Link } from "react-router-dom";
import InputText from "../../components/Forms/InputText/InputText";
import { routes } from "../../shared/router/routes";
import { IPasswordSetup } from "../../shared/interfaces/authentication.interface";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useState } from "react";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import Success from "./../../shared/assets/imgs/success.svg";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import Button from "../../components/Button/Button";

function PasswordRecovery() {
    const { setIsLoading } = useContext(LoadingContext);
    const [isSuccess, setIsSuccess] = useState<boolean | undefined>();
    const { translate } = useContext(TranslationContext);

    const form = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required("Insert email please")
        }),
        validateOnChange: true,
        onSubmit: async (values: { email: string }) => {
            setIsLoading(true);
            setTimeout(() => {
                const isError = Math.random() < 0.5;
                setIsSuccess(isError);
                setIsLoading(false);
            }, 2000);
        }
    });

    return (
        <>
            {isSuccess === undefined && (
                <div className="d-flex flex-column gap-5">
                    <div className="d-flex flex-column gap-2">
                        <h1 className="text-h1-big-bd text-h1-big-bd-xl color-neutrals-darker">
                            {translate("page.password_recovery.title", "Forgot password")}
                        </h1>
                        <p className="text-bd1-rg color-neutrals-darker">
                            {translate(
                                "page.password_recovery.description",
                                "Sit libero fugiat hic laboriosam in molestiae incidunt nesciunt. Saepe omnis eos accusantium"
                            )}
                        </p>
                    </div>
                    <form className="form d-flex flex-column gap-22" onSubmit={form.handleSubmit}>
                        <InputText
                            name="email"
                            label={translate("common.email", "Email")}
                            placeholder={translate("form.placeholder.email", "Insert email")}
                            type="text"
                            customCSS={form.errors.email ? "has_error" : ""}
                            errors={form.submitCount > 0 ? form.errors.email?.split("/") : []}
                            onChange={form.handleChange}
                        />
                        <div className="d-flex flex-column gap-1 mt-12">
                            <Button
                                type="submit"
                                variant="solid"
                                label={translate("common.submit", "Submit")}
                            />
                            <Link
                                to={routes.LOGIN.path}
                                className="color-green-600 text-bd2-bd text-center">
                                {translate("page.login.go_to_login", "Go to login")}
                            </Link>
                        </div>
                    </form>
                </div>
            )}
            {!isSuccess && isSuccess !== undefined && (
                <div className="d-flex flex-column justify-content-center align-items-center mt-5 text-center gap-12">
                    <img src={Success} alt="" aria-hidden="true" />
                    <h1 className="text-h1-md color-neutrals-darker px-5 mx-5">
                        {translate("common.generic_error", "There was an error")}
                    </h1>
                    <span className="text-bd1-rg">
                        {translate("common.retry", "Please try again.")}
                    </span>
                    <button
                        onClick={() => setIsSuccess(undefined)}
                        className="button bg-green-500 color-neutrals-black text-bd2-bd w-100 mt-32">
                        {translate("page.login.change_password", "Change password")}
                    </button>
                </div>
            )}
            {isSuccess && (
                <div className="d-flex flex-column justify-content-center align-items-center mt-5 text-center gap-12">
                    <img src={Success} alt="" aria-hidden="true" />
                    <h1 className="text-h1-md color-neutrals-darker px-5 mx-5">
                        {translate("page.login.password_success", "Password set successfully!")}
                    </h1>
                    <span className="text-bd1-rg">
                        {translate(
                            "page.login.password_success_description",
                            "Now please log in to continue."
                        )}
                    </span>
                    <Link
                        to={routes.LOGIN.path}
                        className="button bg-green-500 color-neutrals-black text-bd2-bd w-100 mt-32">
                        {translate("common.login", "Login")}
                    </Link>
                </div>
            )}
        </>
    );
}

export default PasswordRecovery;
