import { useEffect, useRef, useState } from "react";
import { IVideoProps, IVimeoVideo } from "./Video.interface";
import Player from "@vimeo/player";

function Video(props: IVideoProps) {
    const [videoId, setVideoId] = useState<string>();
    const [timestamp, setTimestamp] = useState<string>("10");
    const [currentTime, setCurrentTime] = useState(0);
    const iframe = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        if (!iframe) return;

        const player = new Player(iframe.current as HTMLIFrameElement);
        const handleVideoTimestamp = (data: IVimeoVideo) => {
            setCurrentTime(Math.round(data.seconds));
        };

        player.on("timeupdate", handleVideoTimestamp);

        return () => {
            player.off("timeupdate", handleVideoTimestamp);
        };
    }, []);

    useEffect(() => {
        // UPDATE DB EVERY 10s
        if (currentTime % 10 === 0) console.log({ currentTime });
    }, [currentTime]);

    useEffect(() => {
        const params = props.url.split("/").pop();
        const [id, timestamp] = params?.split("#t=") ?? [];

        setVideoId(id);
        if (timestamp) setTimestamp(timestamp.replace(/\D/g, ""));
    }, [props.url]);

    return (
        <iframe
            ref={iframe}
            title={props.title}
            src={`https://player.vimeo.com/video/${videoId}#t=${timestamp}s`}
            width="640"
            height="360"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen></iframe>
    );
}

export default Video;
