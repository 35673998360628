export interface IModulesResponse {
    data: {
        modules: IModule[];
    };
}
export interface IModuleResponse {
    data: {
        module: IModule;
    };
}

export interface IModule {
    id: string;
    title: string;
    description: string;
    contentAmount?: number;
    sections?: IModuleSection[];
}

export interface ISectionItem {
    type: ComponentTypes;
    id: string;
    title: string;
    duration: number;
}

export interface IModuleSection {
    title: string;
    type: ComponentTypes;
    items: ISectionItem[];
}

export enum ComponentTypes {
    VIDEO = "ComponentItemVideo",
    FORM = "ComponentItemTypeForm",
    RICH_TEXT = "ComponentItemRichText",
    IMAGE = "ComponentItemImage",
    FILE = "ComponentItemFile",
    AUDIO = "ComponentItemAudio"
};

