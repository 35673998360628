import { useContext, useEffect } from "react";
import { DEFAULT_LANGUAGE } from "../../../shared/constants";
import { useGetModule } from "../../../shared/hooks/useModules";
import { TranslationContext } from "../../../shared/providers/translation/translation.provider";
import { IModule } from "../../../shared/interfaces/modules.interface";
import { IModuleProps } from "./Module.interface";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../shared/router/routes";
import { LoadingContext } from "../../../shared/providers/loading/loading.provider";
import ComponentPicker from "../../../components/ModulesComponents/ComponentPicker/ComponentPicker";

function Module(props: IModuleProps) {
    const navigate = useNavigate();
    const { lang } = useContext(TranslationContext);
    const { setIsLoading } = useContext(LoadingContext);
    const { id } = useParams();
    const { isLoading, data: module } = useGetModule<IModule>(id, lang ?? DEFAULT_LANGUAGE);

    useEffect(() => {
        setIsLoading(isLoading);
        if (isLoading) return;
        if (!module) navigate(routes.MODULES.path);
    }, [isLoading, module, navigate, setIsLoading]);

    return (
        <>
            <div className="row gx-1 gy-2">
                <div className="col-12">
                    <h1 className="text-h1-bd">{module?.title}</h1>
                </div>
                {module?.sections?.map((section, i) => (
                    <article className="col-12" key={i}>
                        <div className="pb-2">
                            <h2 className="text-h2-smbd mb-1">{section.title}</h2>
                            <hr className="is_sm" />
                            {section.items.map((item, i) => (
                                <div className="p-3" key={i}>
                                    <ComponentPicker {...item} />
                                </div>
                            ))}
                        </div>
                    </article>
                ))}
            </div>
        </>
    );
}

export default Module;
