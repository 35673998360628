import { useEffect, useState } from "react";
import { IFavoriteButtonProps } from "./FavoriteButton.interface";

function FavoriteButton({ isFavorite, onClick }: IFavoriteButtonProps) {
    const [isActive, setIsActive] = useState(isFavorite);

    useEffect(() => {
        setIsActive(isFavorite);
    }, [isFavorite]);

    const handleClick = () => {
        setIsActive((v) => !v);
        if (onClick) onClick();
    };

    return (
        <button
            type="button"
            className={isActive ? "color-red-600" : "color-neutrals-neutral"}
            onClick={handleClick}>
            <i
                className={`icon icon-24 ${isActive ? "icon-favorite--filled" : "icon-favorite"}`}></i>
        </button>
    );
}

export default FavoriteButton;
