import { markdownToHtml } from "../../../shared/helpers/utils";
import { IRichTextProps } from "./Richtext.interface";

function RichText(props: IRichTextProps) {
    return (
        <div
            className="text-bd2-md rich_text"
            dangerouslySetInnerHTML={{ __html: markdownToHtml(props.content) }}
        />
    );
}

export default RichText;
