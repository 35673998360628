import { ChangeEvent, useEffect, useState } from "react";
import { IInputCheckboxProps } from "./InputCheckbox.interface";

function InputCheckbox(props: IInputCheckboxProps) {
    const [value, setValue] = useState<string | number | readonly string[]>("");

    useEffect(() => {
        if (!props.value) return;

        setValue(props.value);
    }, [props.value]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) props.onChange(e);
        setValue(e.target.value);
    };

    return (
        <label className="form_field form_field-checkbox">
            <input type="checkbox" {...props} value={value} onChange={onChange} name={props.name} />
            <span className="checkbox_label">{props.label}</span>
        </label>
    );
}

export default InputCheckbox;
