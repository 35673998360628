import { ChangeEvent, useEffect, useState } from "react";
import { IInputSwitchProps } from "./InputSwitch.interface";

function InputSwitch(props: IInputSwitchProps) {
    const [value, setValue] = useState<string | number | readonly string[]>("");

    useEffect(() => {
        if (!props.value) return;

        setValue(props.value);
    }, [props.value]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) props.onChange(e);
        setValue(e.target.value);
    };

    return (
        <label className="form_field form_field-switch">
            <input type="checkbox" {...props} value={value} onChange={onChange} name={props.name} />
            <span className="switch_label">{props.label}</span>
        </label>
    );
}

export default InputSwitch;
