import axios from "axios";
import { clearCookies, getCookies, setCookie } from "../helpers/utils";
import { COOKIES } from "../constants";
import { getNewToken } from "./authentication.service";
import { IAuthTokens, IRequestError } from "../interfaces/authentication.interface";
export type RequestError = { message: string; code?: string };

const DO_NOT_RETRY_ENDPOINTS = ["login", "auth/refresh-token", "change-password"];

export const request = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookies()[COOKIES.TOKEN]}`
    }
});

// TODO remove fixed token
// token will come from login
export const contentRequest = axios.create({
    baseURL: process.env.REACT_APP_BASE_CONTENT_API_URL,
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer a9719d4aa00a2ca81a47be2981158d9e34d51e916c21d620f2f541efabb99019e567374bf2e898f0608dea47b97bf2ab15133b6201bc8236527fa966d40d6dc1f0dc9bf8fda3c046b88320622b214d567cdd47e65319c170bf55d4bcb684a79edd2b7717bb78cb1764620b8ad7a8a586d20a60e274e800cf641acdc5252ea895`
    }
});

export const setRequestAuthHeader = (token: string) => {
    request.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const getError = (err: any) => {
    if (err?.status && err?.code && err?.message) {
        return err;
    }

    const isAxiosError = axios.isAxiosError(err);

    if (isAxiosError && err.response) {
        return {
            status: err.response.status,
            code: err.response.data.code,
            message: err.response.data.message
        };
    } else {
        return {
            status: 400,
            code: 0,
            message:
                "Something went wrong. Please check your internet connection or contact our support."
        };
    }
};

request.interceptors.request.use(
    function (config) {
        config.headers["Authorization"] = `Bearer ${getCookies()[COOKIES.TOKEN]}`;
        return config;
    },
    function (err) {
        return Promise.reject(err);
    }
);

request.interceptors.response.use(
    function (config) {
        return config;
    },
    async function (err) {
        const originalRequest = err.config;
        if (
            (err.response.data.status === "401" || err.status === 401) &&
            !DO_NOT_RETRY_ENDPOINTS.some((k) => originalRequest.url.match(k))
        ) {
            try {
                originalRequest._retry = true;
                const response = await getNewToken(getCookies()[COOKIES.REFRESH_TOKEN]);

                if (response.success) {
                    const resp = response.data as IAuthTokens;
                    setCookie(
                        COOKIES.TOKEN,
                        resp?.access_token,
                        COOKIES.TOKEN_EXP_HOURS,
                        "/",
                        null,
                        true
                    );
                    setCookie(
                        COOKIES.REFRESH_TOKEN,
                        resp?.refresh_token,
                        COOKIES.REFRESH_TOKEN_EXP_HOURS,
                        "/",
                        null,
                        true
                    );

                    request.defaults.headers.common["Authorization"] =
                        `Bearer ${resp.access_token}`;

                    return request(originalRequest);
                } else {
                    throw new Error((response.data as IRequestError).code);
                }
            } catch (e) {
                clearCookies();
                return Promise.reject(getError(e));
            }
        }
        return Promise.reject(getError(err));
    }
);
