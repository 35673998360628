import { Widget } from "@typeform/embed-react";
import { IFormProps } from "./Form.interface";

function Form({ formId }: IFormProps) {
    if (!formId) return <p>No form id provided</p>;
    return (
        <Widget
            id={formId}
            className="typeform"
            onSubmit={(e) => console.log(e)}
            onQuestionChanged={(e) => console.log(e)}
            onStarted={(e) => console.log(e)}
        />
    );
}

export default Form;
