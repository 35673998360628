import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import InputText from "../../components/Forms/InputText/InputText";
import Button from "../../components/Button/Button";
import { Link } from "react-router-dom";
import InputSwitch from "../../components/Forms/InputSwitch/InputSwitch";
import { AuthenticationContext } from "../../shared/providers/authentication/authentication.provider";
import InputCheckbox from "../../components/Forms/InputCheckbox/InputCheckbox";
import { IPasswordChange, IPasswordSetup } from "../../shared/interfaces/authentication.interface";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useChangePassword } from "../../shared/hooks/useAuthentication";

function Account() {
    const { translate } = useContext(TranslationContext);
    const { setIsLoading } = useContext(LoadingContext);
    const [isSuccess, setIsSuccess] = useState<boolean | undefined>();
    const [settingPassword, setSettingPassword] = useState<boolean>(false);
    const { user } = useContext(AuthenticationContext);
    const changePassword = useChangePassword();

    const passwordRequirements = [
        {
            label: "Required",
            test: (v: string) => !v,
            display: false
        },
        {
            label: "Between 8 and 64 characters",
            test: (v: string) => v.length < 8 || v.length > 64,
            display: true
        },
        {
            label: "At least one number",
            test: (v: string) => !/[0-9]/.test(v),
            display: true
        },
        {
            label: "At least one uppercase letter",
            test: (v: string) => !/[A-Z]/.test(v),
            display: true
        },
        {
            label: "At least one special character (!, @, #, $, %, ^, &, *)",
            test: (v: string) => !/[!@#$%^&*]/.test(v),
            display: true
        },
        {
            label: "At least one lowercase letter",
            test: (v: string) => !/[a-z]/.test(v),
            display: true
        }
    ];

    const form = useFormik({
        initialValues: {
            currentPassword: "current password",
            newPassword: "",
            confirmNewPassword: ""
        },
        validationSchema: Yup.object().shape({
            newPassword: Yup.string().test("password-rules", "Invalid password", function (val) {
                const errors: string[] = [];
                const value = val ?? "";

                passwordRequirements.forEach((r) => {
                    if (r.test(value)) errors.push(r.label);
                });

                if (errors.length) {
                    return this.createError({
                        message: errors.join("/")
                    });
                }

                return true;
            }),
            confirmNewPassword: Yup.string()
                .required("Confirm password please")
                .test("confirm-password", "Passwords do not match", function (val) {
                    return this.parent.newPassword === val;
                })
        }),
        validateOnChange: true,
        onSubmit: async (values: IPasswordChange) => {
            setIsLoading(true);
            try {
                console.log(values);
                await changePassword.mutateAsync({
                    currentPassword: values.currentPassword,
                    newPassword: values.newPassword
                });
                setIsSuccess(true);
            } catch (e) {
                setIsSuccess(false);
            }
            setIsLoading(false);
            setSettingPassword(false);
            form.resetForm();
        }
    });

    const passwordLevels = ["", "Very Weak", "Weak", "Medium", "Good", "Very Good"];

    const getCurrentPasswordState = (): number => {
        return form.dirty
            ? Number(passwordLevels.length - 1 - (form.errors.newPassword?.split("/").length ?? 0))
            : 0;
    };

    const checkRequirement = (label: string) => {
        return form.errors.newPassword?.split("/").some((lb) => lb === label);
    };

    return (
        <div className="account">
            <h1 className="text-h2-smbd mb-3">{translate("common.my_account", "My account")}</h1>
            <div className="d-flex flex-column gap-4">
                <section aria-label={translate("account.personal_data", "Personal data")}>
                    <div className="d-flex justify-content-between align-items-center mb-22 flex-wrap gap-12">
                        <h2 className="text-h3-md" id="password_settings">
                            {translate("account.personal_data", "Personal data")}
                        </h2>
                        <Link to={""} className="color-green-500 d-flex align-items-center gap-03">
                            <i className="icon icon-24 icon-help"></i>
                            <span className="text-bd2-bd">
                                {translate(
                                    "account.change_info_request",
                                    "Need to change personal data information? Contact us"
                                )}
                            </span>
                        </Link>
                    </div>
                    <form className="form row gx-5 gy-22">
                        <div className="col-12 col-lg-6">
                            <InputText
                                disabled
                                type="text"
                                label={translate("account.first_name", "First name")}
                                name="firstName"
                                placeholder={translate(
                                    "account.placeholder.first_name",
                                    "Insert first name"
                                )}
                                value={user?.name}
                            />
                        </div>
                        <div className="col-12 col-lg-6 order-2">
                            <InputText
                                disabled
                                type="text"
                                label={translate("account.last_name", "Last name")}
                                name="lastName"
                                placeholder={translate(
                                    "account.placeholder.last_name",
                                    "Insert last name"
                                )}
                                value={user?.name}
                            />
                        </div>
                        <div className="col-12 col-lg-6 order-1">
                            <InputText
                                disabled
                                type="text"
                                label={translate("common.email", "Email")}
                                name="email"
                                placeholder={translate("form.placeholder.email", "Insert email")}
                                value={user?.email}
                                onChange={() => {}}
                            />
                        </div>
                        <div className="col-12 col-lg-6 order-3">
                            <InputText
                                disabled
                                type="text"
                                label={translate("account.job_title", "Job title")}
                                name="jobTitle"
                                placeholder={translate(
                                    "account.placeholder.job_title",
                                    "Insert job title"
                                )}
                                onChange={() => {}}
                            />
                        </div>
                    </form>
                </section>
                <hr className="is_sm" />
                <form
                    className="form"
                    aria-labelledby="password_settings"
                    onSubmit={form.handleSubmit}>
                    <div className="d-flex justify-content-between align-items-center mb-22 flex-wrap gap-12">
                        <h2 className="text-h3-md" id="password_settings">
                            {translate("account.password_settings", "Password settings")}
                        </h2>
                        {!settingPassword && (
                            <Button
                                icon="edit"
                                type="button"
                                variant="outlined"
                                onClick={() => setSettingPassword(true)}
                                label={translate("page.login.change_password", "Change password")}
                            />
                        )}
                        {settingPassword && (
                            <Button
                                icon="edit"
                                type="submit"
                                label={translate(
                                    "account.save_password_settings",
                                    "Save password settings"
                                )}
                            />
                        )}
                    </div>
                    <div className="row gx-5 gy-22">
                        <div className="col-12">
                            <InputText
                                name="currentPassword"
                                label={translate("common.password", "Password")}
                                placeholder={translate(
                                    "form.placeholder.password",
                                    "Insert password"
                                )}
                                type="password"
                                onChange={form.handleChange}
                                value={form.values.currentPassword}
                                disabled={!settingPassword}
                            />
                        </div>
                        {settingPassword && (
                            <>
                                <div className="col-12 col-lg-6">
                                    <InputText
                                        name="newPassword"
                                        label={translate("common.new_password", "New password")}
                                        placeholder={translate(
                                            "form.placeholder.new_password",
                                            "Insert new password"
                                        )}
                                        type="password"
                                        customCSS={form.errors.newPassword ? "has_error" : ""}
                                        currentStep={getCurrentPasswordState()}
                                        totalSteps={
                                            form.errors.newPassword
                                                ? passwordLevels.length - 1
                                                : undefined
                                        }
                                        stepLabel={passwordLevels[getCurrentPasswordState()]}
                                        onChange={form.handleChange}
                                        value={form.values.newPassword}
                                    />
                                    {form.errors.newPassword && (
                                        <div className="form_field mt-1">
                                            <ul className="form_field-requirements">
                                                {passwordRequirements.map(
                                                    (req, i) =>
                                                        req.display && (
                                                            <li
                                                                className="text-bd3-md d-flex gap-02 align-items-start"
                                                                key={i}>
                                                                <i
                                                                    className={`icon icon-16 ${
                                                                        checkRequirement(req.label)
                                                                            ? "icon-misuse color-red-600"
                                                                            : "icon-checkmark--filled color-green-600"
                                                                    }`}></i>
                                                                {req.label}
                                                            </li>
                                                        )
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 col-lg-6">
                                    <InputText
                                        type="password"
                                        label={translate(
                                            "form.confirm_password",
                                            "Confirm password"
                                        )}
                                        name="confirmNewPassword"
                                        placeholder={translate(
                                            "form.placeholder.confirm_password",
                                            "Confirm password"
                                        )}
                                        errors={
                                            form.submitCount > 0
                                                ? form.errors.confirmNewPassword?.split("/")
                                                : []
                                        }
                                        onChange={form.handleChange}
                                        value={form.values.confirmNewPassword}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </form>
                <hr className="is_sm" />
                <form action="">
                    <h2 className="text-h3-md mb-22">
                        {translate("account.privacy_settings", "Privacy settings")}
                    </h2>
                    <div className="row gx-5 gy-3">
                        <div className="col-12 col-lg-6 d-flex gap-22 flex-column">
                            <InputSwitch
                                label="Dorem ipsum dolor sit amet, consectetur adipiscing elit."
                                name="settings-1"
                                onChange={() => {}}
                            />
                        </div>
                        <div className="col-12 col-lg-6 d-flex gap-22 flex-column">
                            <InputCheckbox
                                label="Dorem ipsum dolor sit amet, consectetur adipiscing elit."
                                name="settings-1"
                                onChange={() => {}}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Account;
