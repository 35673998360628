import { useEffect, useState } from "react";
import { CONTENT_BASE_URL } from "../../../shared/constants";
import { IAudioProps } from "./Audio.interface";

function Audio({ url, alt = "" }: IAudioProps) {
    const [timestamp, setTimestamp] = useState<number>(10);
    const [currentTime, setCurrentTime] = useState(0);

    const handleVideoTimestamp = (data: { timeStamp: number }) => {
        setCurrentTime(Math.round(data.timeStamp / 1000));
    };

    useEffect(() => {
        // UPDATE DB EVERY 10s
        if (currentTime % 10 === 0) console.log({ currentTime });
    }, [currentTime]);

    return (
        <audio controls onTimeUpdate={handleVideoTimestamp}>
            <source src={`${CONTENT_BASE_URL}${url}#t=${timestamp}`} type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
    );
}

export default Audio;
