import { ILogin, IPasswordChange } from "../interfaces/authentication.interface";
import { login, setNewPassword } from "../services/authentication.service";
import { useMutation } from "react-query";


export function useAuthLogin() {
    return useMutation((payload: ILogin) => login(payload));
}
export function useChangePassword(){
    return useMutation((payload: IPasswordChange) => setNewPassword(payload))
}