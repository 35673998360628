import { useContext } from "react";
import ModuleCard from "../../components/ModuleCard/ModuleCard";
import { DEFAULT_LANGUAGE } from "../../shared/constants";
import { useGetModules } from "../../shared/hooks/useModules";
import { IModulesProps } from "./Modules.interface";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import { IModule } from "../../shared/interfaces/modules.interface";

function Modules(props: IModulesProps) {
    const { lang } = useContext(TranslationContext);
    const { data: modules } = useGetModules<IModule[]>(lang ?? DEFAULT_LANGUAGE);

    return (
        <>
            <div className="row gx-1">
                {modules?.map((module) => (
                    <div className="col-3" key={module.id}>
                        <ModuleCard
                            {...module}
                            title={module.title}
                            contentAmount={module.contentAmount}
                            description={module.description}
                        />
                    </div>
                ))}
            </div>
        </>
    );
}

export default Modules;
