export const routes = {
    HOME: { path: '/' },
    MODULES: { path: '/' },
    MODULE: { path: '/module/:id' },
    LOGIN: { path: '/login' },
    PASSWORD_RESET: { path: '/login/password' },
    PASSWORD_RECOVERY: { path: '/login/password-recovery' },
    MY_ACCOUNT: { path: '/account' },
    CONTACTS: { path: '/contacts' },
    FAQS: { path: '/faqs' },
    PAGE_404: { path: '/404' },
    STYLEGUIDE: { path: '/styleguide' },
    TRANSLATIONS: { path: '/translations' }
};
