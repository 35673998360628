import { clearCookies } from "../helpers/utils";
import {
    IAuthTokens,
    ILogin,
    IPasswordChange,
    IRequest,
    IRequestError
} from "../interfaces/authentication.interface";
import { request } from "./requests.service";

export const login = async (
    credentials: ILogin
): Promise<IRequest<IAuthTokens | IRequestError>> => {
    const headers = {
        "Content-Type": "application/json"
    };
    try {
        const response = await request.post<IAuthTokens>(`/ohp/login`, credentials, {
            headers
        });

        return { success: true, data: response.data };
    } catch (error: any) {
        return { success: false, data: error as IRequestError };
    }
};

export const getNewToken = async (
    refreshToken: string
): Promise<IRequest<IAuthTokens | IRequestError>> => {
    const headers = {
        "Content-Type": "application/json"
    };
    try {
        const response = await request.post<IAuthTokens>(
            `/auth/refresh-token`,
            { refreshToken },
            {
                headers
            }
        );

        return { success: true, data: response.data };
    } catch (error: any) {
        return { success: false, data: error as IRequestError };
    }
};

export const setNewPassword = async (
    passwords: IPasswordChange
): Promise<IRequest<any | IRequestError>> => {
    try {
        const response = await request.post<any>(`/ohp/change-password`, {
            ...passwords
        });

        return { success: true, data: response.data };
    } catch (error: any) {
        return { success: false, data: error as IRequestError };
    }
};

export const logout = async (): Promise<IRequest<null | IRequestError>> => {
    try {
        await request.get<any>(`/auth/logout`);
        clearCookies();

        return { success: true, data: null };
    } catch (error: any) {
        return { success: false, data: error as IRequestError };
    }
};
