import { useQuery, UseQueryOptions } from "react-query";
import { IModule } from "../interfaces/modules.interface";
import { getModule, getModules } from "../services/modules.service";

export function useGetModules<T extends any = IModule[]>(
    locale: string,
    options?: UseQueryOptions<any, unknown, T>
) {
    return useQuery({
        queryKey: ["modules", locale],
        queryFn: () => getModules(locale).then((r) => r.data),
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
        ...options
    });
}

export function useGetModule<T extends any = IModule[]>(
    id?: string,
    locale?: string,
    options?: UseQueryOptions<any, unknown, T>
) {
    return useQuery({
        queryKey: ["modules", id, locale],
        queryFn: () => {
            if (!id || !locale) return;

            return getModule(id, locale).then((r) => r.data);
        },
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
        ...options
    });
}
