import { ComponentTypes } from "../../../shared/interfaces/modules.interface";
import Audio from "../AudioComponent/Audio";
import { IAudioProps } from "../AudioComponent/Audio.interface";
import Form from "../FormComponent/Form";
import { IFormProps } from "../FormComponent/Form.interface";
import Image from "../ImageComponent/Image";
import RichText from "../RichTextComponent/RichText";
import Video from "../VideoComponent/Video";

function ComponentPicker({ type, ...props }: { type: ComponentTypes; [key: string]: any }) {
    if (type === ComponentTypes.IMAGE && props.image) {

        return <Image {...props.image} />;
    } else if (type === ComponentTypes.RICH_TEXT) {

        return <RichText title={props.title} content={props.content} />;
    } else if (type === ComponentTypes.VIDEO) {

        return <Video title={props.title} url={props.url} />;
    } else if (type === ComponentTypes.AUDIO) {

        return <Audio {...props.audio as IAudioProps} />;
    } else if (type === ComponentTypes.FORM) {

        return <Form {...props as IFormProps} />;
    }

    return <>{type}</>;
}

export default ComponentPicker;
