import {
  IRequest,
  IRequestError,
} from "../interfaces/authentication.interface";
import { IUser } from "../interfaces/user.interface";
import { request } from "./requests.service";

export const getUserDetails = async (): Promise<
  IRequest<IUser | IRequestError>
> => {
  try {
    const response = await request.get<IUser>(`/employee/user`);
    return { success: true, data: response.data as IUser };
  } catch (error: any) {
    // TODO remove the following data when endpoint changes
    return { success: true, data: {name: "Diogo", "email": "dchen@innovagency.com", id: ""} as IUser };
    return { success: false, data: error as IRequestError };
  }
};
